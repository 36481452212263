/** @format */

import React, { useState, useEffect } from "react";
import { FadeLoader } from "react-spinners";
import { useGetProviderSubmittedAssessmentsQuery } from "store/services/providerAPI";
import ProviderSubmissionTable from "views/providers/components/table/fellows/ProviderSubmissionTable";

const ProviderSubmissions = () => {
  const [skip, setSkip] = useState(false);
  const [filter, setFilter] = useState(`page=1&week_number=12`);

  const {
    data,
    isLoading: fetchingAssesment,
    isFetching,
  } = useGetProviderSubmittedAssessmentsQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);

  return (
    <div className="row">
      {fetchingAssesment ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
          <ProviderSubmissionTable
            resources={data?.data?.results}
            metadata={data?.data?.metadata}
            setFilter={setFilter}
            setSkip={setSkip}
            filtering={fetchingAssesment || isFetching}
            filter={filter}
          />
      )}
    </div>
  );
};
export default ProviderSubmissions;
