/** @format */
import { encrypt } from "utils/secure";

import { createSlice } from "@reduxjs/toolkit";
import { Path } from "utils/constant.data";

const initialState = {
  isAuth: false,
  currentUser: null,
  isLoading: false,
  fromLogin: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuth: (state, { payload }) => {
      state.isAuth = payload;
    },
    setCurrentUser: (state, { payload }) => {
      state.currentUser = { ...payload };
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setFromLogin: (state, { payload }) => {
      state.fromLogin = payload;
    },
  },
});

export const { setIsAuth, setCurrentUser, setLoading, setFromLogin } =
  authSlice.actions;
export const authSelector = (state) => state.auth;
export default authSlice.reducer;

export const setUser = (payload) => async (dispatch) => {
  dispatch(setCurrentUser(payload));
};

export const setActionSource = (payload) => async (dispatch) => {
  window.localStorage.setItem("3MTFLS", payload);
  dispatch(setFromLogin(payload));
};

export const login = (payload) => async (dispatch) => {
  if (payload?.access_token && payload?.user_data?.account_type) {
    await dispatch(setIsAuth(true));
    await dispatch(setActionSource(true));
    await dispatch(setCurrentUser(payload?.user_data));
    window.localStorage.setItem("3MTAT", encrypt(payload?.access_token));
    window.localStorage.setItem("3MTFLS", true);
    window.localStorage.setItem(
      "3MTUSER",
      encrypt(JSON.stringify(payload?.user_data))
    );

    window.open(
      payload?.user_data?.account_type === "FELLOW"
        ? Path.FELLOW_DASHBOARD
        : payload?.user_data?.account_type === "PROVIDER"
        ? Path.PROVIDER_DASHBOARD
        : Path.LOGIN,
      "_self"
    );
  }
};

export const logOut = () => async (dispatch) => {
  window.localStorage.removeItem("3MTAT");
  window.localStorage.removeItem("3MTUSER");
  window.open(Path.LOGIN, "_self");
};
