/**
 * eslint-disable no-unused-vars
 *
 * @format
 */

/** @format */

import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { capitalize } from "utils/strings";
// import { formatDate } from "utils/date";
import { ColumnFilter } from "./helpers/Columns/ColumnFilter";
import FilterModal from "./components/FilterModal";
import { FadeLoader } from "react-spinners";
import format from "date-fns/format";

const ProviderAssesmentsTable = ({
  resources,
  metadata,
  setFilter,
  setSkip,
  messageClass,
  filtering,
}) => {
  const [modalFilter, setModalFilter] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filtered, setFiltered] = useState(false);

  useEffect(() => {
    setSkip(true);
  }, [resources, setSkip]);

  const BROADCAST_COLUMNS = [
    {
      Header: "Assement Name",
      accessor: "id",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.title)}`;
      },
    },

    {
      Header: "Cohort",
      accessor: "cohort",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return value?.rank ? `Cohort ${value?.rank}` : "General";
      },
    },

    {
      Header: "Week Number",
      accessor: "module",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return `${value?.week_number}`;
      },
    },

    {
      Header: "Start Date",
      accessor: "start_date",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return value ? `${format(new Date(value), "d MMMM, yyyy")}` : "Null";
      },
    },

    {
      Header: "Duration",
      accessor: "duration",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return value ? `${value} Days` : "Null";
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.status)}`;
      },
    },

    // {
    //   Header: "Actions",
    //   disableSortBy: true,
    //   sortable: false,
    //   accessor: "",
    //   Filter: ColumnFilter,
    //   disableFilters: true,
    //   Cell: ({ cell }) => {
    //     const [showProfile, setShowProfile] = useState(false);

    //     const { fellow, assessment } = cell?.row?.original;

    //     const [
    //       approveFellowFellowAssessment,
    //       { isLoading: updatingAssesment },
    //     ] = useApproveFellowFellowAssessmentMutation();

    //     const handleSubmit = async (values) => {
    //       await approveFellowFellowAssessment({
    //         assessment_id: assessment?.id,
    //         fellow_id: fellow?.id,
    //         status: values.status,
    //       })
    //         .unwrap()
    //         .then((data) => {
    //           toast.success(`✔️ Application submitted succesfully!`, {
    //             position: "top-right",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //           });
    //         })
    //         .catch((error) => {
    //           const formattedError = extractErrorMessage(error);
    //           Swal.fire({
    //             title: replaceUnderscoresWithSpaces(error?.data.detail),
    //             iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
    //             text: formattedError,
    //             showCloseButton: true,
    //             customClass: {
    //               icon: "no-border",
    //             },
    //             confirmButtonText: "Try Again",
    //           });
    //         });
    //     };

    //     const formik = useFormik({
    //       initialValues: {
    //         status: "",
    //       },
    //       validationSchema: FormSchema,
    //       onSubmit: handleSubmit,
    //       enableReinitialize: true,
    //     });

    //     return (
    //       <>
    //         <Modal
    //           show={showProfile}
    //           onHide={() => setShowProfile(false)}
    //           className="modal fade"
    //           id="statusModal"
    //         >
    //           <div className="modal-content">
    //             <div className="modal-header">
    //               <h3 className="modal-title">Approve Fellow's Submission</h3>
    //               <Button
    //                 variant=""
    //                 type="button"
    //                 className="close"
    //                 data-dismiss="modal"
    //                 onClick={() => setShowProfile(false)}
    //               >
    //                 <span>×</span>
    //               </Button>
    //             </div>
    //             <div className="modal-body">
    //               <div className="col-xl-12">
    //                 <div className="course-dedails-bx">
    //                   <div className="border-0 pb-0">
    //                     <h5>{capitalize(assessment?.title)}</h5>
    //                   </div>
    //                   <div className="pt-0">
    //                     <div className="description">
    //                       <h6>{capitalize(fellow?.first_name)}</h6>
    //                     </div>

    //                     <div className="description">
    //                       <p>{capitalize(fellow?.fellow_id)}</p>
    //                     </div>

    //                     <div className="description">
    //                       <p>{capitalize(assessment?.description)}</p>
    //                     </div>

    //                     <div className="description">
    //                       <a
    //                         target="_blank"
    //                         rel="noopener noreferrer"
    //                         className="text-primary text-bold"
    //                         href={assessment?.link}
    //                       >
    //                         View Assignment
    //                       </a>
    //                     </div>

    //                     <form className="mt-4" onSubmit={formik.handleSubmit}>
    //                       <div className="form-group mb-3">
    //                         <label className="mb-1">
    //                           <strong>Update Status</strong>
    //                         </label>
    //                         <select
    //                           id="status"
    //                           name="status"
    //                           className="form-control"
    //                           value={formik.values.status}
    //                           onChange={formik.handleChange}
    //                           onBlur={formik.handleBlur}
    //                         >
    //                           <option>Choose Status</option>
    //                           {["approved", "disapproved"].map((state) => {
    //                             return (
    //                               <option value={state}>
    //                                 {capitalize(state)}
    //                               </option>
    //                             );
    //                           })}
    //                         </select>
    //                         {formik.errors.status && formik.touched.status ? (
    //                           <div className="text-danger mt-2 fs-12">
    //                             {formik.errors.status}
    //                           </div>
    //                         ) : null}
    //                       </div>
    //                       {formik.values?.status === "disapproved" && (
    //                         <div className="mb-3">
    //                           <label className="mb-1">
    //                             <strong>Reason</strong>
    //                           </label>

    //                           <textarea
    //                             className="w-100 form-control"
    //                             placeholder="Your reason"
    //                             id="reason"
    //                             name="reason"
    //                             rows="3"
    //                             {...formik.getFieldProps("reason")}
    //                           />

    //                           <div className="d-flex align-items-center justify-content-between">
    //                             {formik.errors.reason &&
    //                             formik.touched.reason ? (
    //                               <div className="text-danger mt-2 fs-12">
    //                                 {formik.errors.reason}
    //                               </div>
    //                             ) : null}
    //                           </div>
    //                         </div>
    //                       )}

    //                       <div className="d-flex w-100 justify-content-center align-items-center">
    //                         <Button
    //                           type="submit"
    //                           className="ms-2 pull-right  py-3 btn-pigment-green"
    //                         >
    //                           {updatingAssesment ? (
    //                             <span
    //                               role="status"
    //                               aria-hidden="true"
    //                               className="spinner-border spinner-border-sm ms-2 text-white"
    //                             ></span>
    //                           ) : (
    //                             "Submit"
    //                           )}
    //                         </Button>
    //                       </div>
    //                     </form>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </Modal>
    //         {/* Profile Modal */}

    //         <Dropdown className="dropdown ms-auto text-right">
    //           <Dropdown.Toggle
    //             variant=""
    //             className="btn-link i-false"
    //             data-toggle="dropdown"
    //           >
    //             <svg
    //               width="24px"
    //               height="24px"
    //               viewBox="0 0 24 24"
    //               version="1.1"
    //             >
    //               <g
    //                 stroke="none"
    //                 strokeWidth={1}
    //                 fill="none"
    //                 fillRule="evenodd"
    //               >
    //                 <rect x={0} y={0} width={24} height={24} />
    //                 <circle fill="#000000" cx={5} cy={12} r={2} />
    //                 <circle fill="#000000" cx={12} cy={12} r={2} />
    //                 <circle fill="#000000" cx={19} cy={12} r={2} />
    //               </g>
    //             </svg>
    //           </Dropdown.Toggle>
    //           <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
    //             <Dropdown.Item onClick={() => setShowProfile(true)}>
    //               Update Status
    //             </Dropdown.Item>
    //           </Dropdown.Menu>
    //         </Dropdown>
    //       </>
    //     );
    //   },
    // },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => BROADCAST_COLUMNS, []);
  const data = useMemo(() => resources, [resources]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  const handleAllFilter = (_modalFilter, _page) => {
    const filterString = `page=${_page}${
      String(modalFilter?.week_number)?.length > 0
        ? `&week_number=${modalFilter?.week_number}`
        : ""
    }${
      String(modalFilter?.cohort_number ?? "")?.length > 0
        ? `&cohort_number=${modalFilter?.cohort_number}`
        : ""
    }${
      modalFilter?.module_id?.length > 0
        ? `&module_id=${modalFilter?.module_id}`
        : ""
    }`;

    setFiltered(true);
    console.log(filterString);
    setFilter(filterString);
    setShowFilter(false);
  };

  useEffect(() => {
    if (modalFilter) {
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  return (
    <>
      <FilterModal
        showModal={showFilter}
        setShowFilter={setShowFilter}
        setFilter={setModalFilter}
        filtering={filtering}
      />
      <div className="card">
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <h4 className="card-title text-pigment-green">All Assesments</h4>

            {filtered && (
              <button
                onClick={() => {
                  setFiltered(false);
                  setFilter(`page=1`);
                  setModalFilter(null);
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>

          <div className="d-flex  align-items-center">
            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={() => {
                setSkip(true);
                setShowFilter(true);
              }}
            >
              Filter
            </button>
          </div>
        </div>
        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {data?.length > 0 ? (
              <div className="table-responsive">
                <div className="dataTables_wrapper">
                  <table
                    {...getTableProps()}
                    className="table dataTable display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {" "}
                                  {cell.render("Cell")}{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                    <button
                      className={`btn btn-outline ${
                        hasPrev ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page - 1) || 1;
                        setFilter(
                          `page=${_newPage}&message_class=${messageClass}`
                        );
                      }}
                    >
                      Previous
                    </button>

                    {metadata?.count && (
                      <div className="d-flex">
                        <p className="mb-0">
                          Page <strong>{metadata?.page}</strong> of{" "}
                          <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                          &nbsp;
                        </p>
                        <p className="mb-0">
                          Total <strong>{metadata?.count}</strong>
                        </p>
                      </div>
                    )}

                    <button
                      className={`btn btn-outline ${
                        hasNext ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page || 0) + 1;
                        setFilter(
                          `page=${_newPage}&message_class=${messageClass}`
                        );
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">No data for the specified filter</h4>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProviderAssesmentsTable;
