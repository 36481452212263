/** @format */

import { Suspense } from "react";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom.scss";

import Page404 from "./views/general/404Page";
import { ToastContainer } from "react-toastify";

import {
  GuestRoute,
  FellowRoute,
  ProviderRoute,
  CommunityRoute,
} from "config/auth";
import { Path } from "utils/constant.data";
import { Route, Routes, Navigate } from "react-router-dom";

import {
  ProviderRoutes,
  FellowRoutes,
  PublicRoutes,
  CommunityRoutes,
} from "utils/routes";

import SelectedFellows from "views/general/SelectedFellows";
import { getLocalStorage } from "utils/secure";

function App() {
  const user = localStorage.getItem("3MTUSER")
    ? getLocalStorage("3MTUSER")
    : null;




  return (
    <>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Routes>
          <Route element={<GuestRoute user={user} />}>
            {PublicRoutes.map((_data) => (
              <Route
                key={_data.title}
                exact
                path={_data.path}
                element={_data.element}
              />
            ))}
            <Route path="/" element={<Navigate replace to={Path.LOGIN} />} />
          </Route>
          <Route element={<ProviderRoute user={user} />}>
            {ProviderRoutes.map((data) => (
              <Route
                key={data.title}
                exact
                path={data.path}
                element={data.element}
              />
            ))}
          </Route>
          <Route element={<FellowRoute user={user} />}>
            {FellowRoutes.map((data) => (
              <Route
                key={data.title}
                exact
                path={data.path}
                element={data.element}
              />
            ))}
          </Route>
          <Route element={<CommunityRoute user={user} />}>
            {CommunityRoutes.map((data) => (
              <Route
                key={data.title}
                exact
                path={data.path}
                element={data.element}
              />
            ))}
          </Route>
          <Route path={Path.SELECTED_FELLOWS} element={<SelectedFellows />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </>
  );
}

export default App;
