/** @format */

import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import profile from "images/3MTT/user-avatar.png";
import { useGetFellowNotificationsQuery } from "store/services/notification";
import { ReactComponent as BellIcon } from "images/svg/bell.svg";
import { ReactComponent as EnvIcon } from "images/svg/envelope.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import dayjs from "dayjs";
import {  capitalizeFirstWord } from "utils/strings";
import { useUpdateFellowNotificationsMutation } from "store/services/notification";

const Header = ({ onNote }) => {
  const [headerFix, setheaderFix] = useState(false);
  const { data } = useGetFellowNotificationsQuery("message_class=notification");
  const { data: broadcasts } = useGetFellowNotificationsQuery(
    "message_class=broadcast"
  );
  const [updateAssesment, { isLoading }] =
    useUpdateFellowNotificationsMutation();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  // useEffect(() => {
  //   if (data) {
  //     const allIds =
  //       data?.data?.results
  //         ?.filter((item) => !item?.is_read)
  //         .map((item) => item.id) ?? [];

  //     const allBroadcastIds =
  //       broadcasts?.data?.results
  //         ?.filter((item) => !item?.is_read)
  //         .map((item) => item.id) ?? [];

  //     const finalIds = [...allIds, ...allBroadcastIds];

  //     finalIds?.length > 0 &&
  //       updateAssesment({
  //         message_ids: [...finalIds],
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, broadcasts]);

  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;

  const NotificationBlog = ({ classChange, notification }) => {
    return (
      <>
        <li>
          <div className="timeline-panel">
            <div className={`media me-2 ${classChange}`}>
              <i className="fa fa-info" />
            </div>
            <div className="media-body">
              <h6 className="mb-1 d-flex align-items-center justify-content-between">
                {capitalizeFirstWord(notification?.message?.message_title)}!
                {!notification?.is_read ? (
                  <div className="notification-circle"></div>
                ) : null}
              </h6>
              <p>{ capitalizeFirstWord(notification?.message?.message_body)}</p>
              {/* <p>{notification?.message?.message_body}</p> */}

              <small className="d-block">
                {dayjs(notification?.created_at).format("ddd, D MMMM YYYY")}
              </small>
            </div>
          </div>
        </li>
      </>
    );
  };

  const allIds =
    data?.data?.results
      ?.filter((item) => !item?.is_read)
      .map((item) => item.id) ?? [];

  const allBroadcastIds =
    broadcasts?.data?.results
      ?.filter((item) => !item?.is_read)
      .map((item) => item.id) ?? [];


// console.log('finalName[1]',finalName)

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ") === "dashboard dark"
                  ? "Dashboard"
                  : finalName.join(" ").length > 9
                  ? finalName[1] === "attendance" ? "Attendance" :  finalName[0] === "foundational"? 'Foundational Course': finalName[0] === "certificates" ? "Certificates": finalName[0] === "assesments" ? "Assessment" : "Forum"
                  :  filterName.includes("gigs") ? "Jobs & Gigs": finalName.join(" ")}
              </div>
            </div>
            <div className="navbar-nav header-right">
              <div className="dlab-side-menu">
                <div className="search-coundry d-flex align-items-center"></div>

                {/* <Socialbar onNote={onNote} /> */}

                <ul className="notice__bar">
                  <Dropdown
                    as="li"
                    className="nav-item dropdown header-profile"
                  >
                    <Dropdown.Toggle
                      variant=""
                      as="a"
                      className="nav-link i-false c-pointer"
                    >
                      <img src={profile} width={20} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      align="right"
                      className="dropdown-menu dropdown-menu-end"
                    >
                      <LogoutPage />
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown
                    as="li"
                    className="nav-item dropdown notification_dropdown u-l"
                  >
                    <div className="position-relative">
                      {data?.data?.results?.filter(
                        (_notification) =>
                          !_notification?.is_read &&
                          _notification?.recipient_type === "fellow"
                      )?.length > 0 ? (
                        <div className="notification-badge">
                          <p>
                            {
                              data?.data?.results?.filter(
                                (_notification) =>
                                  !_notification?.is_read &&
                                  _notification?.recipient_type === "fellow"
                              )?.length
                            }
                          </p>
                        </div>
                      ) : null}
                    </div>

                    <Dropdown.Toggle
                      className="nav-link i-false c-pointer"
                      variant=""
                      as="a"
                    >
                      <BellIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      align="right"
                      className="mt-2 dropdown-menu dropdown-menu-end"
                    >
                      <div className="notification__header">
                        <h4>Notifications here</h4>
                        {allIds?.length > 0 && (
                          <button
                            className="btn"
                            onClick={() => {
                              updateAssesment({
                                message_ids: [...allIds],
                              });
                            }}
                          >
                            {isLoading ? (
                              <span
                                role="status"
                                aria-hidden="true"
                                className="spinner-border spinner-border-sm ms-2"
                              ></span>
                            ) : (
                              "Mark all as read"
                            )}
                          </button>
                        )}
                      </div>

                      {data?.data?.results?.length > 0 ? (
                        <PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
                          <ul className="timeline">
                            {data?.data?.results
                              ?.filter(
                                (_notification) =>
                                  _notification?.recipient_type === "fellow"
                              )
                              .map((_notification) => {
                                return (
                                  <NotificationBlog
                                    notification={_notification}
                                    classChange="media-success"
                                    key={_notification?.id}
                                  />
                                );
                              })}

                            {/* <NotificationBlog classChange="media-info" />
                            <NotificationBlog classChange="media-success" /> */}
                          </ul>

                          <div
                            className="ps__rail-x"
                            style={{ left: 0, bottom: 0 }}
                          >
                            <div
                              className="ps__thumb-x"
                              tabIndex={0}
                              style={{ left: 0, width: 0 }}
                            />
                          </div>
                          <div
                            className="ps__rail-y"
                            style={{ top: 0, right: 0 }}
                          >
                            <div
                              className="ps__thumb-y"
                              tabIndex={0}
                              style={{ top: 0, height: 0 }}
                            />
                          </div>
                        </PerfectScrollbar>
                      ) : (
                        <ul className="empty_notification">
                          <li>
                            <p>No notifications at the moment</p>
                          </li>
                        </ul>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown
                    as="li"
                    className="nav-item dropdown notification_dropdown u-l"
                  >
                    <div className="position-relative">
                      {broadcasts?.data?.results?.filter(
                        (_notification) =>
                          !_notification?.is_read &&
                          _notification?.recipient_type === "fellow"
                      )?.length > 0 ? (
                        <div className="notification-badge">
                          <p>
                            {
                              broadcasts?.data?.results?.filter(
                                (_notification) =>
                                  !_notification?.is_read &&
                                  _notification?.recipient_type === "fellow"
                              )?.length
                            }
                          </p>
                        </div>
                      ) : null}
                    </div>

                    <Dropdown.Toggle
                      className="nav-link i-false c-pointer"
                      variant=""
                      as="a"
                    >
                      <EnvIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      align="right"
                      className="mt-2 dropdown-menu dropdown-menu-end"
                    >
                      <div className="notification__header">
                        <h4>Broadcasts</h4>

                        {allBroadcastIds.length > 0 && (
                          <button
                            className="btn"
                            onClick={() => {
                              updateAssesment({
                                message_ids: [...allBroadcastIds],
                              });
                            }}
                          >
                            {isLoading ? (
                              <span
                                role="status"
                                aria-hidden="true"
                                className="spinner-border spinner-border-sm ms-2"
                              ></span>
                            ) : (
                              "Mark all as read"
                            )}
                          </button>
                        )}
                      </div>

                      {broadcasts?.data?.results?.length > 0 ? (
                        <PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
                          <ul className="timeline">
                            {broadcasts?.data?.results
                              ?.filter(
                                (_notification) =>
                                  _notification?.recipient_type === "fellow"
                              )
                              .map((_notification) => {
                                return (
                                  <NotificationBlog
                                    notification={_notification}
                                    classChange="media-info"
                                    key={_notification?.id}
                                  />
                                );
                              })}
                          </ul>

                          <div
                            className="ps__rail-x"
                            style={{ left: 0, bottom: 0 }}
                          >
                            <div
                              className="ps__thumb-x"
                              tabIndex={0}
                              style={{ left: 0, width: 0 }}
                            />
                          </div>
                          <div
                            className="ps__rail-y"
                            style={{ top: 0, right: 0 }}
                          >
                            <div
                              className="ps__thumb-y"
                              tabIndex={0}
                              style={{ top: 0, height: 0 }}
                            />
                          </div>
                        </PerfectScrollbar>
                      ) : (
                        <ul className="empty_notification">
                          <li>
                            <p>No broadcast at the moment</p>
                          </li>
                        </ul>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
