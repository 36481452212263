/** @format */

import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import {
  Nigerian_States,
  OrganisationTypes,
  LocalGov,
} from "utils/constant.data";

import { level_one } from "utils/constant.data";
import { formatHyphen } from "utils/strings";

const ProfileLevelOne = ({
  profile,
  formik,
  validateFormStep,
  setStep,
  LGAs,
  setLGAs,
  updating,
  handleSubmit
  
}) => {


  return (
    <Fragment>
      <div className="mb-3">
        <label className="mb-1">
          <strong>Name Of Organisation</strong>
        </label>
        <input
          type="text"z
          className="form-control"
          id="name"
          name="name"
          placeholder="Name"
          disabled={true}
          {...formik.getFieldProps("name")}
        />
        {formik.errors.name ? (
          <div className="text-danger mt-2 fs-12">{formik.errors.name}</div>
        ) : null}
      </div>

      <div className="form-group mb-3">
        <label className="mb-1">
          <strong>What state does your organisation operate in Nigeria?</strong>
        </label>
        <select
          defaultValue={profile ? profile?.state : ""}
          id="state"
          name="state"
          className="form-control"
          onChange={(e) => {
            const selectedState = e.target.value;
            formik.setFieldValue("state", selectedState);
            formik.setFieldTouched("state", true);

            const _selectedLGAs =
              LocalGov.find(
                (val) =>
                  val?.state?.toLowerCase() === selectedState.toLowerCase()
              )?.lgas || [];

            setLGAs([..._selectedLGAs]);
          }}
        >
          {Nigerian_States.map((state) => {
            return <option value={state}>{state}</option>;
          })}
        </select>
        {formik.errors.state ? (
          <div className="text-danger mt-2 fs-12">{formik.errors.state}</div>
        ) : null}
      </div>

      <div className="form-group mb-3">
        <label className="mb-1">
          <strong>Local Government</strong>
        </label>

        <select
          id="lga"
          name="lga"
          className="form-control"
          {...formik.getFieldProps("lga")}
          onChange={(e) => {
            if (e.target.value?.length > 0) {
              formik.setFieldValue("lga", e.target.value);
              formik.setFieldError("lga", "");
            } else {
              formik.setFieldError("lga", `Local Government is required`);
            }
          }}
        >
          <option value="">Select Local Government</option>

          {LGAs
            ? LGAs.map((state) => {
                return <option value={state}>{state}</option>;
              })
            : null}
        </select>

        {formik.errors.lga ? (
          <div className="text-danger mt-2 fs-12">{formik.errors.lga}</div>
        ) : null}

        {/* {customError.lga.length > 0 ? (
          <div className="text-danger mt-2 fs-12">{customError.lga}</div>
        ) : null} */}
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>Address</strong>
        </label>
        <input
          type="text"
          className="form-control"
          id="address"
          name="address"
          placeholder="Address"
          {...formik.getFieldProps("address")}
        />
        {formik.errors.address ? (
          <div className="text-danger mt-2 fs-12">{formik.errors.address}</div>
        ) : null}
      </div>

      <div className="form-group mb-3">
        <label className="mb-1">
          <strong>Organisation Type</strong>
        </label>
        <select
          defaultValue={profile ? profile?.organisation_type : ""}
          id="organisation_type"
          name="organisation_type"
          disabled={profile && !(profile.organisation_type === "" || profile.organisation_type === null)}
          className="form-control"
          {...formik.getFieldProps("organisation_type")}
          onChange={(e) => {
            if (e.target.value?.length > 0) {
              formik.setFieldValue("organisation_type", e.target.value);
              formik.setFieldError("organisation_type", ``);
            } else {
              formik.setFieldError(
                "organisation_type",
                `Organisation type is required`
              );
            }
          }}
        >
          <option value="">Select Organisation type</option>
          {OrganisationTypes.map((type) => {
            return <option value={type}>{formatHyphen(type)}</option>;
          })}
        </select>
        {formik.errors.organisation_type ? (
          <div className="text-danger mt-2 fs-12">
            {formik.errors.organisation_type}
          </div>
        ) : null}
      </div>

      {formik?.values?.organisation_type &&
        formik?.values?.organisation_type !==
          "Government-Academic-Institution" && (
          <div className="mb-3">
            <label className="mb-1">
              <strong>Company registration number (CAC)</strong>
            </label>
            <input
              type="text"
              className="form-control"
              id="cac"
              name="cac"
              placeholder="cac"
              {...formik.getFieldProps("cac")}
              disabled={profile && !(profile.cac === "" || profile.cac === null)}
            />
            {formik.errors.cac ? (
              <div className="text-danger mt-2 fs-12">{formik.errors.cac}</div>
            ) : null}
          </div>
        )}

      <div className="mb-3">
        <label className="mb-1">
          <strong>Website</strong>
        </label>
        <input
          type="text"
          className="form-control"
          id="website"
          name="website"
          placeholder="website"
          {...formik.getFieldProps("website")}
        />
        {formik.errors.website ? (
          <div className="text-danger mt-2 fs-12">{formik.errors.website}</div>
        ) : null}
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>Social Media</strong>
        </label>
        <input
          type="text"
          className="form-control"
          id="social_media"
          name="social_media"
          // disabled={profile?.is_profile_completed}
          placeholder="Social Media"
          {...formik.getFieldProps("social_media")}
        />
        {formik.errors.social_media ? (
          <div className="text-danger mt-2 fs-12">
            {formik.errors.social_media}
          </div>
        ) : null}
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>POC Name</strong>
        </label>
        <input
          type="text"
          className="form-control"
          id="poc_fullname"
          name="poc_fullname"
          placeholder="Poc Fullname"
          {...formik.getFieldProps("poc_fullname")}
        />
        {formik.errors.poc_fullname ? (
          <div className="text-danger mt-2 fs-12">
            {formik.errors.poc_fullname}
          </div>
        ) : null}
      </div>

      <div className="mb-3 pe-none">
        <label className="mb-1 pe-none ">
          <strong>POC Email</strong>
        </label>
        <input
          type="text"
          className="form-control pe-none "
          id="email"
          name="poc_email"
          placeholder="Poc Email"
          disabled={true}
          {...formik.getFieldProps("poc_email")}
        />
        {formik.errors.email ? (
          <div className="text-danger mt-2 fs-12">{formik.errors.email}</div>
        ) : null}
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>POC Phone Number</strong>
        </label>
        <input
          type="text"
          className="form-control"
          id="poc_phone"
          name="poc_phone"
          placeholder="Poc Phone"
          {...formik.getFieldProps("poc_phone")}
        />
        {formik.errors.poc_phone ? (
          <div className="text-danger mt-2 fs-12">
            {formik.errors.poc_phone}
          </div>
        ) : null}
      </div>

      <div className="mb-3">
        <label className="mb-1">
        <strong>Organisation Phone Number</strong>
        </label>
        <input
          type="text"
            className="form-control"
            id="organisation_phone_number"
            name="organisation_phone_number"
            placeholder="Organisation Phone"
            {...formik.getFieldProps("organisation_phone_number")}
        />
        {formik.errors.organisation_phone_number ? (
        <div className="text-danger mt-2 fs-12">
            {formik.errors.organisation_phone_number}
        </div>
        ) : null}
    </div>

      <div className="mb-3">
        <label className="mb-1">
        <strong>Organisation Email</strong>
        </label>
        <input
          type="text"
            className="form-control"
            id="organisation_email"
            name="organisation_email"
            placeholder="Organisation Email"
            {...formik.getFieldProps("organisation_email")}
        />
        {formik.errors.organisation_email ? (
        <div className="text-danger mt-2 fs-12">
            {formik.errors.organisation_email}
        </div>
        ) : null}
      </div>

      <div className="mb-3">
        <label className="mb-1">
        <strong>Fellows Whatsapp group</strong>
        </label>
        <input
          type="text"
            className="form-control"
            id="whatsapp_group_link"
            name="whatsapp_group_link"
            placeholder="Fellows Whatsapp group"
            {...formik.getFieldProps("whatsapp_group_link")}
        />
        {formik.errors.whatsapp_group_link ? (
        <div className="text-danger mt-2 fs-12">
            {formik.errors.whatsapp_group_link}
        </div>
        ) : null}
      </div>

      <div className="d-flex w-100 justify-content-flex-start align-items-center">
        <Button
          type="button"
          disabled={updating}        
          onClick={() => {
            const L1_ERROR = validateFormStep(level_one);
            if (L1_ERROR) {
              return
            }
            handleSubmit(formik.values, level_one, 1)
            }
          }
          className="ms-2 pull-right  py-3 btn-pigment-green"
        >
          {updating ? (
            <span
              role="status"
              aria-hidden="true"
              className="spinner-border spinner-border-sm ms-2 text-white"
            ></span>
          ) : (
            "Save"
          )}
        </Button>
        <Button
          onClick={() => {
          const L1_ERROR = validateFormStep(level_one);
            if (!L1_ERROR) {
              setStep(2);
            }
          }}
          type="button"
          className="ms-2 pull-right  py-3 btn-pigment-green"
          disabled={updating}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default ProfileLevelOne;