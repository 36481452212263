/** @format */

import React, { useState, useEffect } from "react";
import ProviderLayout from "views/providers/Provider";
import ProviderFellowsTable from "../../components/table/fellows/ProviderFellowsTable";
import { useGetAllFellowsQuery } from "store/services/adminAPI";
import { FadeLoader } from "react-spinners";

const ProviderFellows = () => {
  const [skip, setSkip] = useState(false);
  const [filter, setFilter] = useState(`page=1`);

  const {
    data,
    isLoading: fetchingFellows,
    isFetching,
  } = useGetAllFellowsQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);



  return (
    <ProviderLayout>
      <div className="row">
        {fetchingFellows ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <ProviderFellowsTable
            fellows={data?.data?.results}
            metadata={data?.data?.metadata}
            setFilter={setFilter}
            setSkip={setSkip}
            filtering={fetchingFellows || isFetching}
            filter={filter}
          />
        )}
      </div>
    </ProviderLayout>
  );
};
export default ProviderFellows;
