/**
 * eslint-disable no-unused-vars
 *
 * @format
 */

/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React, { Fragment, useEffect, useState } from "react";
import Educat from "images/education-girl.png";
import Calpng from "images/vector/calpng.png";
import Book from "images/vector/book.png";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import CourseBlog from "../../components/Dashboard/Dashboard/CourseBlog";
import { getProviderProfile } from "store/actions/profile";
import { profileSelector } from "store/reducers/ProfileReducer";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Alert } from "react-bootstrap";
import { Path } from "utils/constant.data";
import FellowLayout from "../../Fellow";
import { useGrantFellowConsentQuery } from "store/services/adminAPI";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { Link } from "react-router-dom";
import SkillModal from "./components/SkillModal";
import QuestionsModal from "./components/QuestionsModal";
import PromptModal from "./components/Prompt";
import { mapLevel, removeSpaces } from "utils/strings";
import { ReactComponent as InfoIcon } from "images/svg/info.svg";
import { Modal, Button } from "react-bootstrap";
import MyCoursesCard from "./components/MyCourses";
import MyProviderCard from "./components/MyProvider";
import HowTo from "./components/HowTo";
import { setActionSource } from "store/reducers/AuthReducer";
import MMLogo from "images/3MTT/main.png";
import { ReactComponent as PlayIcon } from "images/svg/play.svg";
import ModalVideo from "react-modal-video";
import CertificateModal from "./components/CertificateModal";
import { validStatuses } from "utils/strings";

const FellowHome = () => {
  const dispatch = useDispatch();
  const { profileInfo, isLoading } = useSelector(profileSelector);
  const [showCourses, setShowCourses] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const navigate = useNavigate();
  const [proceedGrant, setProceedGrant] = useState(true);

  useEffect(() => {
    getProfileInfo();
    const isFromLogin = window.localStorage.getItem("3MTFLS")
      ? window.localStorage.getItem("3MTFLS") === "false"
        ? false
        : true
      : false;
    setShowReminder(isFromLogin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getProfileInfo = () => {
    dispatch(getProviderProfile());
  };

  const profile = profileInfo?.data?.profile
    ? profileInfo?.data?.profile
    : null;

 
  const show = validStatuses.includes(profile?.status?.toLowerCase());

  useEffect(() => {
    if (profileInfo?.data?.profile) {
      const level = mapLevel(profileInfo?.data?.profile?.technical_skill_level);
      const isSkillProfileUpdated =
        profileInfo?.data?.profile?.is_skill_profile_updated;
      const isQuestionnaireUpdated =
        profileInfo?.data?.profile?.is_questionaire_updated;
      const isSkillTestTaken = profileInfo?.data?.profile?.is_skill_test_taken;

      if (
        level !== "Beginner" &&
        (!isSkillProfileUpdated || !isQuestionnaireUpdated || !isSkillTestTaken)
      ) {
        setShowPrompt(true);
      } else if (!isSkillProfileUpdated || !isQuestionnaireUpdated) {
        setShowPrompt(true);
      }

      const rank = profileInfo?.data?.profile?.cohort?.rank ?? 0;
      if (rank === 1) {
        setShowCertificate(true);
      }
    }
  }, [profileInfo]);

  const {
    data: grantConsentData,
    isLoading: grantingConsent,
    error,
    isError,
  } = useGrantFellowConsentQuery(undefined, {
    skip: proceedGrant,
  });

  useEffect(() => {
    if (grantConsentData) {
      Swal.fire({
        title: "",
        icon: "success",
        text: "Congratulations, you have been accepted as a fellow for this phase.",
        showCloseButton: true,
        customClass: {
          icon: "no-border",
        },
        confirmButtonText: "Get Started",
      }).then(() => {
        dispatch(getProviderProfile()).then(() => {});
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantConsentData]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title:
          replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
        text: error?.data?.detail,
        iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
        showCloseButton: true,
        customClass: {
          icon: "no-border",
        },
        confirmButtonText: "Try Again",
      }).then(() => {
        setProceedGrant(true);
      });
    }
  }, [isError, error]);

  const grantConsent = () => {
    setProceedGrant(false);
  };

  return (
    <FellowLayout>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <Fragment>
          <div className="row w-100">
            <div className="col-xl-12 col-xxl-12 col-lg-12">
              <div className="row">
                <div className="col-xl-12 col-xxl-12">
                  <div className="card dlab-join-card fellow-dashboard h-auto">
                    <div className="card-body">
                      <div className="dlab-media d-flex justify-content-between">
                        <div className="dlab-content">
                          {profile?.first_name && (
                            <h4>
                              Hello {removeSpaces(profile?.first_name)}, Welcome
                              to your 3MTT Dashboard
                            </h4>
                          )}
                        </div>
                        <div className="dlab-img">
                          <img src={Educat} alt="" />
                        </div>
                        <div className="dlab-icon">
                          <img src={Calpng} alt="" className="cal-img" />
                          <img src={Book} alt="" className="book-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 col-xxl-12">
                  <Alert variant="info" className="alert-dismissible fade show">
                    <InfoIcon />

                    <Link className="text-white " to={Path.SELECTED_FELLOWS}>
                      See list of all selected fellows for Cohort 2{" "}
                      <span className="text-decoration-underline">here</span>
                    </Link>
                  </Alert>
                </div>

                {/* <div className="col-xl-12 col-xxl-12"> 
                  <Alert variant="info" className="alert-dismissible fade show">
                    <InfoIcon />
                    Join Episode 8 of our ‘My Career in Tech’ webinar series on
                    the 15th December at 4:00pm
                    <a
                      className="text-white text-decoration-underline"
                      href="https://www.youtube.com/live/M-fdj3xEiys?feature=share"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Fellows Experience; Week 2
                      <span className="text-decoration-underline"></span>
                    </a>
                    <a
                      className="text-white text-decoration-underline"
                      href="https://www.youtube.com/live/M-fdj3xEiys?feature=share"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Fellows Experience; Week 2
                      <span className="text-decoration-underline"></span>
                    </a>
                  </Alert>
                </div> */}

                <div className="col-xl-12">
                  <CourseBlog />
                </div>

                <div className="col-xl-12">
                  {show ? (
                    <>
                      {profile?.consent_accepted ? (
                        <HowTo />
                      ) : (
                        <Card>
                          <Card.Header>
                            <Card.Title>Selection Update</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <p>You have been selected as a 3MTT Fellow!</p>
                            <p>
                              The training phases of the 3MTT programme begins
                              with you as a fellow in either first 1% phase
                              (30,000 of a target 3,000,000), the 10% phase
                              (300,000 of a target 3,000,000) or the 100% phase
                              (3,000,000). We are pleased to inform you that
                              your training with an assigned Applied Learning
                              Cluster (ALC) will be communicated as you
                              progress. Ahead of the training, you will have
                              access to introductory courses via this portal.
                            </p>
                            <p>
                              To proceed further, please confirm your acceptance
                              by agreeing to all the requirements stated below:
                            </p>
                            <p>
                              1. You will dedicate the required time and effort
                              for the completion of your courses online and
                              attendance of all weekly physical engagements with
                              your training provider during the 3-month training
                              period
                            </p>
                            <p>
                              2. You can dedicate at least 72 hours (minimum of
                              6hours/week) to this program.
                            </p>
                            <p>
                              3. You are able to commit resources, such as
                              laptop, internet and transport fare to participate
                              in virtual and physical activities required for
                              this training programme.
                            </p>
                            <p>
                              4. You agree to adhere to a high standard of
                              conduct with other fellows in the programme and
                              your Applied Learning Cluster (ALC).
                            </p>
                            <p>
                              5. Actively participate and complete all required
                              assessments during the programme and beyond.
                            </p>
                            <p>
                              By accepting, you acknowledge that you have read,
                              understood, and agree to the obligations stated
                              above.
                            </p>

                            {!profile?.consent_accepted && (
                              <button
                                className="btn btn-danger"
                                onClick={() => grantConsent()}
                                disabled={grantingConsent}
                              >
                                {grantingConsent ? (
                                  <span
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner-border spinner-border-sm ms-2 text-white"
                                  ></span>
                                ) : (
                                  " Accept"
                                )}
                              </button>
                            )}
                          </Card.Body>
                        </Card>
                      )}
                    </>
                  ) : (
                    <Card>
                      <Card.Header>
                        <Card.Title>Application Status</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        {profile?.status.toLowerCase() ===
                          "application_incomplete" && (
                          <>
                            <p>
                              Welcome to 3MTT! Please complete the steps below
                              to finish your profile. Once done, you'll be ready
                              for your learning adventure.
                            </p>
                            <p>
                              You will be a part of our learning community where
                              we will be providing learning support and
                              additional resources to you.
                            </p>

                            <p>
                              After completing your profile and while you wait,
                              explore this introductory course to AI:
                            </p>

                            <p>
                              <a
                                href="https://openclassrooms.com/en/courses/7078811-destination-ai-introduction-to-artificial-intelligence"
                                rel="noreferrer"
                                target="_blank"
                                className="text-primary"
                              >
                                Introduction to Artificial Intelligence
                              </a>
                            </p>

                            <p>
                              Share your certificate and experience on social
                              media using the hashtag #3MTTLearningCommunity for
                              a chance to receive advanced certification
                              credits.
                            </p>
                          </>
                        )}

                        {profile?.status.toLowerCase() ===
                          "application_completed" && (
                          <>
                            <p>
                              You are a part of our 3MTT Learning Community and
                              will be in queue for future phases of the 3MTT
                              training program.
                            </p>
                            <p>
                              The learning community will provide you with
                              self-paced resources that you can leverage on your
                              learning journey. In the learning community, you
                              will have companions on your learning journey
                              through connections with other learners in your
                              local government and state learning similar skills
                              and the support of a dedicated community manager
                              to guide you along the way.
                            </p>
                            <p>
                              You can begin your learning journey with some
                              introductory courses in your skill area in the
                              resources section.
                            </p>
                            <p>
                              To access the courses for free on Coursera, after
                              clicking on "Enroll for Free", you may be required
                              to signup or login.
                            </p>
                            <p>
                              After proceeding with either step (sign up or
                              login), kindly click on "Audit the course" which
                              is below the description of the subscription plan
                              displayed after you sign up or login, to gain
                              access to the course for free.
                            </p>
                            <p>
                              Please note that you do not have to subscribe or
                              start the free trial.
                            </p>
                            <p>
                              We are excited to support your learning journey.
                            </p>
                            <div className="col-xs-12 col-sm-6 col-lg-4">
                              <div className="card">
                                <div className="card-body">
                                  <div className="d-flex justify-content-between flex-wrap mb-2">
                                    <h3>
                                      Accessing your course for free on coursera
                                    </h3>
                                  </div>
                                  <div className="video-img style-1 video-image">
                                    <div className="view-demo">
                                      <img src={MMLogo} alt="" />
                                      <div className="play-button text-center">
                                        <Link
                                          to={"#"}
                                          className="popup-youtube"
                                          onClick={() => {
                                            setOpen(true);
                                          }}
                                        >
                                          <PlayIcon />
                                        </Link>
                                      </div>
                                      <ModalVideo
                                        channel="custom"
                                        autoplay
                                        isOpen={isOpen}
                                        onClose={() => setOpen(false)}
                                        theme="dark"
                                        url="https://public-assets-nitda.s3.us-east-2.amazonaws.com/3mtt-videos/Accessing+your+course+for+free+on+Coursera.mp4"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <p>
                          Thank you for your cooperation, and enjoy your 3MTT
                          journey.
                        </p>

                        <Row>
                          <Col lg={12}>
                            <div className="grid-col fellow-profile mb-4 d-flex justify-content-between align-items-center">
                              <div>
                                <h5>Confirm your skill and technical level</h5>
                                {!profile?.is_skill_profile_updated && (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => setShowCourses(true)}
                                  >
                                    Confirm Skill
                                  </button>
                                )}
                              </div>
                              <div>
                                {profile?.is_skill_profile_updated ? (
                                  <i
                                    class="fa fa-check text-success"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-times text-danger"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="grid-col fellow-profile mb-4 d-flex justify-content-between align-items-center">
                              <div>
                                <h5> Take our questionnaire</h5>

                                {!profile?.is_questionaire_updated && (
                                  <button
                                    disabled={
                                      profile?.is_skill_profile_updated
                                        ? false
                                        : true
                                    }
                                    className="btn btn-danger"
                                    onClick={() => {
                                      navigate(Path.FELLOW_SURVEY);
                                    }}
                                  >
                                    Take questionnaire
                                  </button>
                                )}
                              </div>
                              <div>
                                {profile?.is_questionaire_updated ? (
                                  <i
                                    class="fa fa-check text-success"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-times text-danger"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </div>
                            </div>
                          </Col>

                          {profile &&
                            mapLevel(profile?.technical_skill_level) !==
                              "Beginner" && (
                              <Col lg={12}>
                                <div className="grid-col fellow-profile mb-4 d-flex justify-content-between align-items-center">
                                  <div>
                                    <h5> Take our skill test</h5>
                                    {!profile?.is_skill_test_taken && (
                                      <button
                                        className="btn btn-danger"
                                        disabled={
                                          profile?.is_questionaire_updated &&
                                          profile?.is_skill_profile_updated
                                            ? false
                                            : true
                                        }
                                        onClick={() =>
                                          navigate(Path.FELLOW_SKILL_TEST)
                                        }
                                      >
                                        Take Test
                                      </button>
                                    )}
                                  </div>
                                  <div>
                                    {profile?.is_skill_test_taken ? (
                                      <i
                                        class="fa fa-check text-success"
                                        aria-hidden="true"
                                      ></i>
                                    ) : (
                                      <i
                                        class="fa fa-times text-danger"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            )}
                        </Row>
                      </Card.Body>
                    </Card>
                  )}
                </div>

                <div className="row">
                  <MyProviderCard />
                  <MyCoursesCard />
                </div>
              </div>
            </div>
          </div>

          {profile?.status?.toLowerCase() === "selected" && (
            <Modal className="modal fade" show={showReminder} size="sm">
              <div className="modal-content">
                <div className="modal-header border-0 justify-content-center">
                  <h5 className="modal-title">Complete Your Course</h5>
                </div>
                <div className="modal-body py-0 text-center border-0 shadow-0">
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className=" mb-3">
                        <p>
                          The clock is ticking! Remember to complete your course
                          on your online learning platform
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Modal.Footer className="border-0 justify-content-center">
                <Button
                  onClick={() => {
                    dispatch(setActionSource(false));
                    setShowReminder(false);
                  }}
                  variant="primary"
                >
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          <SkillModal
            showCourses={showCourses}
            setShowCourses={setShowCourses}
            profile={profile}
            setShowQuestions={setShowQuestions}
          />

          <QuestionsModal
            showQuestions={showQuestions}
            setShowQuestion={setShowQuestions}
            setShowQuestions={setShowQuestions}
            profile={profile}
          />

          <PromptModal showPrompt={showPrompt} setShowPrompt={setShowPrompt} />
          <CertificateModal
            showPrompt={showCertificate}
            setShowPrompt={setShowCertificate}
          />
        </Fragment>
      )}
    </FellowLayout>
  );
};

export default FellowHome;
