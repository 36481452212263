/** @format */

import { Path } from "utils/constant.data";

import ProviderHome from "views/providers/pages/dashboard";
import ProviderFellows from "views/providers/pages/Fellows";
import ProviderProfile from "views/providers/pages/Profile";
import ProviderFellowsCohorts from "views/providers/pages/Fellows/FellowsCohorts";
import ProviderCohortFellows from "views/providers/pages/Fellows/CohortFellows";
import ProviderBroadcast from "views/providers/pages/Broadcast";
import ProviderNotifications from "views/providers/pages/Broadcast/notifications";
import ProviderFaqs from "views/providers/pages/FAQS";
import ProviderChatList from "views/providers/pages/Chat";
import ProviderChatRoom from "views/providers/pages/Chat/room";
import ProviderAttendance from "views/providers/pages/Attendance";
import ProviderAssesments from "views/providers/pages/Assesment";

import FellowHome from "views/fellows/pages/Dashboard";
import FellowSkillTest from "views/fellows/pages/SkillTest";
import FellowProfile from "views/fellows/pages/Profile";
import FellowCourse from "views/fellows/pages/Course";
import FellowFaqs from "views/fellows/pages/FAQS";
import FellowAttendance from "views/fellows/pages/Attendance";
import FellowJobs from "views/fellows/pages/Jobs";
import FellowSurveys from "views/fellows/pages/Surveys";
import FellowOnlineLearningCertificates from "views/fellows/pages/certificates";

import Login from "views/auth/Login";
import Register from "views/auth/Register";
import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPasswod";
import ResetEmailPage from "views/auth/ResetEmail";
import Apply from "views/auth/Register/Apply";

import CommunityHome from "views/community/pages/Dashboard";
import CommunityProfile from "views/community/pages/Profile";
// import CommunityPage from "views/community/pages/Community";

import FellowPerks from "views/fellows/pages/FellowPerks";
import ProviderCourses from "views/providers/pages/Courses";
import FellowSurvey from "views/fellows/pages/Survey";

import FellowChatList from "views/fellows/pages/Chat";
import FellowChatRoom from "views/fellows/pages/Chat/room";

import VerifyFellowCertification from "views/general/VerifyFellowCertification";

import FellowCluster from "views/fellows/pages/Community/Cluster";
import FellowEvents from "views/fellows/pages/Community/Events";
import FellowResources from "views/fellows/pages/Community/Resources";
import FellowNotice from "views/fellows/pages/Community/Notice";
import FellowWebinars from "views/fellows/pages/Community/Webinars";
import PublicJobs from "views/general/Jobs";
import FoundationalCourse from "views/fellows/pages/Course/foundational";
import FellowAssesments from "views/fellows/pages/Assesments";
// import SelectedFellows from "views/general/SelectedFellows";

const PublicRoutes = [
  {
    path: Path.LOGIN,
    element: <Login />,
    title: Path.LOGIN,
  },
  {
    path: Path.REGISTER,
    element: <Register />,
    title: Path.REGISTER,
  },
  {
    path: Path.FORGOT_PASSWORD,
    element: <ForgotPassword />,
    title: Path.FORGOT_PASSWORD,
  },
  {
    path: Path.RESET_PASSWORD,
    element: <ResetPassword />,
    title: Path.RESET_PASSWORD,
  },
  {
    path: Path.RESET_EMAIL,
    element: <ResetEmailPage />,
    title: Path.RESET_EMAIL,
  },
  {
    path: Path.VERIFY_CERTIFICATION,
    element: <VerifyFellowCertification />,
    title: Path.VERIFY_CERTIFICATION,
  },
  {
    path: Path.APPLY,
    element: <Apply />,
    title: Path.APPLY,
  },
  {
    path: Path.PUBLIC_JOBS,
    element: <PublicJobs />,
    title: Path.PUBLIC_JOBS,
  },
  // {
  //   path: Path.SELECTED_FELLOWS,
  //   element: <SelectedFellows />,
  //   title: Path.SELECTED_FELLOWS,
  // },
];

const ProviderRoutes = [
  {
    path: Path.PROVIDER_DASHBOARD,
    element: <ProviderHome />,
    title: Path.PROVIDER_DASHBOARD,
  },

  {
    path: Path.PROVIDER_FELLOWS_COHORTS,
    element: <ProviderFellowsCohorts />,
    title: Path.PROVIDER_FELLOWS_COHORTS,
  },
  {
    path: Path.PROVIDER_COHORT_FELLOWS,
    element: <ProviderCohortFellows />,
    title: Path.PROVIDER_COHORT_FELLOWS,
  },

  {
    path: Path.PROVIDER_FELLOWS,
    element: <ProviderFellows />,
    title: Path.PROVIDER_FELLOWS,
  },

  {
    path: Path.PROVIDER_ATTENDANCE,
    element: <ProviderAttendance />,
    title: Path.PROVIDER_ATTENDANCE,
  },

  {
    path: Path.PROVIDER_COHORT_FELLOWS,
    element: <ProviderFellows />,
    title: Path.PROVIDER_COHORT_FELLOWS,
  },

  {
    path: Path.PROVIDER_PROFILE,
    element: <ProviderProfile />,
    title: Path.PROVIDER_PROFILE,
  },
  {
    path: Path.PROVIDER_COURSE,
    element: <ProviderCourses />,
    title: Path.PROVIDER_COURSE,
  },
  {
    path: Path.PROVIDER_BROADCAST,
    element: <ProviderBroadcast />,
    title: Path.PROVIDER_BROADCAST,
  },
  {
    path: Path.PROVIDER_NOTIFICATIONS,
    element: <ProviderNotifications />,
    title: Path.PROVIDER_NOTIFICATIONS,
  },
  {
    path: Path.PROVIDER_FAQS,
    element: <ProviderFaqs />,
    title: Path.PROVIDER_FAQS,
  },

  {
    path: Path.PROVIDER_FORUM,
    element: <ProviderChatList />,
    title: Path.PROVIDER_FORUM,
  },
  {
    path: Path.PROVIDER_CHAT,
    element: <ProviderChatRoom />,
    title: Path.PROVIDER_CHAT,
  },
  {
    path: Path.PROVIDER_ASSESMENTS,
    element: <ProviderAssesments />,
    title: Path.PROVIDER_ASSESMENTS,
  },
];

const FellowRoutes = [
  {
    path: Path.FELLOW_DASHBOARD,
    element: <FellowHome />,
    title: Path.FELLOW_DASHBOARD,
  },
  {
    path: Path.FELLOW_PROFILE,
    element: <FellowProfile />,
    title: Path.FELLOW_PROFILE,
  },
  {
    path: Path.FELLOW_SKILL_TEST,
    element: <FellowSkillTest />,
    title: Path.FELLOW_SKILL_TEST,
  },
  {
    path: Path.COMMUNITY_WEBINARS,
    element: <FellowWebinars />,
    title: Path.COMMUNITY_WEBINARS,
  },
  {
    path: Path.COMMUNITY_NOTICE,
    element: <FellowNotice />,
    title: Path.COMMUNITY_NOTICE,
  },
  {
    path: Path.COMMUNITY_CLUSTER,
    element: <FellowCluster />,
    title: Path.COMMUNITY_CLUSTER,
  },
  {
    path: Path.COMMUNITY_RESOURCES,
    element: <FellowResources />,
    title: Path.COMMUNITY_RESOURCES,
  },
  {
    path: Path.COMMUNITY_EVENTS,
    element: <FellowEvents />,
    title: Path.COMMUNITY_EVENTS,
  },
  {
    path: Path.FELLOW_PERKS,
    element: <FellowPerks />,
    title: Path.FELLOW_PERKS,
  },
  {
    path: Path.FELLOW_SURVEY,
    element: <FellowSurvey />,
    title: Path.FELLOW_SURVEY,
  },
  {
    path: Path.FELLOW_MY_COURSES,
    element: <FellowCourse />,
    title: Path.FELLOW_MY_COURSES,
  },
  {
    path: Path.FELLOW_FORUM,
    element: <FellowChatList />,
    title: Path.FELLOW_FORUM,
  },
  {
    path: Path.FELLOW_CHAT,
    element: <FellowChatRoom />,
    title: Path.FELLOW_CHAT,
  },
  {
    path: Path.FELLOW_FAQS,
    element: <FellowFaqs />,
    title: Path.FELLOW_FAQS,
  },
  {
    path: Path.FELLOW_JOBS,
    element: <FellowJobs />,
    title: Path.FELLOW_JOBS,
  },
  {
    path: Path.FELLOW_CONFIRM_ATTENDANCE,
    element: <FellowAttendance />,
    title: Path.FELLOW_CONFIRM_ATTENDANCE,
  },
  {
    path: Path.FELLOW_SURVEYS,
    element: <FellowSurveys />,
    title: Path.FELLOW_SURVEYS,
  },
  {
    path: Path.COMMUNITY_COURSE,
    element: <FoundationalCourse />,
    title: Path.COMMUNITY_COURSE,
  },
  {
    path: Path.FELLOW_ONLINE_LEARNING_CERTIFICATES,
    element: <FellowOnlineLearningCertificates />,
    title: Path.FELLOW_ONLINE_LEARNING_CERTIFICATES,
  },
  {
    path: Path.FELLOW_ASSESMENTS,
    element: <FellowAssesments />,
    title: Path.FELLOW_ASSESMENTS,
  },
  
];

const CommunityRoutes = [
  {
    path: Path.COMMUNITY_DASHBOARD,
    element: <CommunityHome />,
    title: Path.COMMUNITY_DASHBOARD,
  },
  {
    path: Path.COMMUNITY_PROFILE,
    element: <CommunityProfile />,
    title: Path.COMMUNITY_PROFILE,
  },
];

export { ProviderRoutes, FellowRoutes, PublicRoutes, CommunityRoutes };
