/** @format */
import { Col, Tab, Nav } from "react-bootstrap";
import React, { useState } from "react";
import ProviderLayout from "views/providers/Provider";

import ProviderAssigments from "./Assignments";
import ProviderSubmissions from "./Submissions";

const ProviderAssesments = () => {
  const [activeTab, setActive] = useState("assesments");
  // const [activeTab, setActive] = useState("submissions");
  const tabData = [
    {
      name: "Assesments",
      title: "assesments",
    },
    {
      name: "Submissions",
      title: "submissions",
    },
  ];

  return (
    <ProviderLayout>
      <div className="widget-heading d-flex justify-content-between align-items-center">
        <h3 className="m-0">Assesmnets</h3>
      </div>

      <div className="row">
        <Col xl={12}>
          <section className="custom-tab-1">
            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
              <Nav as="ul" className="nav-tabs mb-4">
                {tabData.map((data) => (
                  <Nav.Item
                    as="li"
                    key={data.title}
                    onClick={() => setActive(data.title)}
                  >
                    <Nav.Link eventKey={data.name.toLowerCase()}>
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>

              {activeTab === "assesments" && <ProviderAssigments />}
              {activeTab === "submissions" && <ProviderSubmissions />}
            </Tab.Container>
          </section>
        </Col>
      </div>
    </ProviderLayout>
  );
};
export default ProviderAssesments;
