/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";
import baseQueryWithReauth from "./baseQuery";

export const providerApi = createApi({
  reducerPath: "providerApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [ API_TAGS.FETCH_COURSE_MODULES,
              API_TAGS.CREATE_ASSESSMENT,
              API_TAGS.FETCH_PROVIDER_SUBMITTED_ASSESMENTS
            ],
  endpoints: (builder) => ({
    getCourseModule: builder.query({
      query: (courseId) => `courses/detail/${courseId}`,
      providesTags: [API_TAGS.FETCH_COURSE_MODULES],
    }),
    getFellowCourseModule: builder.query({
      query: () => `courses/fellow/modules`,
    }),
    getProviderCourseModule: builder.query({
      query: (query) => `courses/provider/modules${query?.length > 0 ? `?${query}` : ""}`,
    }),
    getFellowRooms: builder.query({
      query: () => `forum/myrooms`,
      providesTags: [API_TAGS.FETCH_FELLOW_ROOMS],
    }),
    getFellowMessages: builder.query({
      query: (roomId) => `forum/messages/${roomId}`,
      providesTags: [API_TAGS.FETCH_FELLOW_MESSAGES],
    }),
    getProviderCourses: builder.query({
      query: () => `courses/provider/get`,
      providesTags: [API_TAGS.GET_PROVIDER_COURSES],
    }),
   
    createAssesment: builder.mutation({
      query: (body) => ({
        url: "courses/assesssments",
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.CREATE_ASSESSMENT],
      invalidatesTags: [API_TAGS.FETCH_COURSE_MODULES],
    }),
    updateAssesment: builder.mutation({
      query: (body) => ({
        url: `courses/assesssments/${body?.assesment_id}`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.UPDATE_ASSESSMENT],
      invalidatesTags: [API_TAGS.FETCH_COURSE_MODULES],
    }),
    deleteAssesment: builder.mutation({
      query: (assesment_id) => ({
        url: `courses/assesssments/${assesment_id}`,
        method: "DELETE",
      }),
      providesTags: [API_TAGS.DELETE_ASSESSMENT],
      invalidatesTags: [API_TAGS.FETCH_COURSE_MODULES],
    }),
    submitFellowAssesment: builder.mutation({
      query: (body) => ({
        url: `courses/mark/fellow/assessment/${body.assesment_id}/submitted`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.DELETE_ASSESSMENT],
    }),
    bulkMarkAttendance: builder.mutation({
      query: (body) => ({
        url: `attendance/records`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.MARK_ATTENDANCE],
      invalidatesTag: [API_TAGS.GET_FELLOWS_ATTENDANCE],
    }),

    getFellowAttendance: builder.query({
      query: () => `attendance/fellow/records`,
      providesTags: [API_TAGS.GET_FELLOW_ATTENDANCE],
    }),
    getFellowsAttendance: builder.query({
      query: (query) => `attendance/marking/fellows/record${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.GET_FELLOWS_ATTENDANCE],
    }),
    confirmFellowAttendance: builder.mutation({
      query: (body) => ({
        url: `attendance/records/${body.attendance_id}/fellow/confirm`,
        method: "PATCH",
        body,
      }),
      providesTags: [API_TAGS.CONFIRM_FELLOW_ATTENDANCE],
      invalidatesTag: [API_TAGS.GET_FELLOW_ATTENDANCE],
    }),
    bulkUpdateFellowStatus: builder.mutation({
      query: (body) => ({
        url: "provider/myfellows/bulk/status/update",
        method: "PATCH",
        body: {
          status: body?.status,
          fellows: body?.fellows
        }
      }),      
      invalidatesTags: [API_TAGS.FETCH_PROVIDER_FELLOWS],
    }),


    retrieveFellowAvailableAssesmnets: builder.query({
      query: (query) => `courses/available/fellow/assessments${query.length > 0 ? `?${query}` : ""}`,
    }),
    getFellowAssesmentsSubmission: builder.query({
      query: (query) => `courses/fellow/assessment/submissions${query.length > 0 ? `?${query}` : ""}`,
    }),
    submitFellowNewAssesment: builder.mutation({
        query: (body) => ({
          url: `courses/submit/fellow/assessment/${body.assessment_id}`,
          method: "PATCH",
          body,
        }),
    }),


    // courses/submitted/assesssments/by/fellow/provider?week_number=10
    getProviderSubmittedAssessments: builder.query({
      query: (query) => `courses/submitted/assesssments/by/fellow/provider${query.length > 0 ? `?${query}` : ""}`,
      providesTags: [API_TAGS.FETCH_PROVIDER_SUBMITTED_ASSESMENTS],
    }),
    getProviderAssessments: builder.query({
      query: (query) => `courses/provider/assessments${query.length > 0 ? `?${query}` : ""}`,
    }),
    approveFellowFellowAssessment: builder.mutation({
        query: (body) => ({
          url:`courses/fellow/assessment/${body.assessment_id}/fellow/${body.fellow_id}`,
          method: "PATCH",
          body,
          invalidatesTags: [API_TAGS.FETCH_PROVIDER_SUBMITTED_ASSESMENTS],
        }),
    }),
    bulkApproveAssessmentsStatus: builder.mutation({
      query: (body) => ({
        url:`courses/bulk/updated/fellow/assessments/status`,
        method: "POST",
        body,
      }),
  }),

    getSingleCourseModule: builder.query({
      query: (query) =>
        `courses/modules?page=1&page_size=120&course_ids=${query}`,
    }),

  }),
});

export const {
  useGetCourseModuleQuery,
  useGetFellowCourseModuleQuery,
  useGetProviderCourseModuleQuery,
  useGetProviderCoursesQuery,
  useCreateAssesmentMutation,
  useUpdateAssesmentMutation,
  useDeleteAssesmentMutation,

  useGetFellowRoomsQuery,
  useGetFellowMessagesQuery,
  useSubmitFellowAssesmentMutation,
  useBulkMarkAttendanceMutation,
  useGetFellowAttendanceQuery,
  useGetFellowsAttendanceQuery,
  useConfirmFellowAttendanceMutation,
  useBulkUpdateFellowStatusMutation,

  useRetrieveFellowAvailableAssesmnetsQuery,
  useGetFellowAssesmentsSubmissionQuery,
  useSubmitFellowNewAssesmentMutation,

  useGetProviderSubmittedAssessmentsQuery,
  useGetProviderAssessmentsQuery,
  useApproveFellowFellowAssessmentMutation,
  useBulkApproveAssessmentsStatusMutation,

  useGetSingleCourseModuleQuery
} = providerApi;
