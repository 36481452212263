/** @format */

import React, { Fragment, useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import { Col, Dropdown, Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import EmptyNotice from "component/ErrorNotice";
import {
  useRetrieveFellowAvailableAssesmnetsQuery,
  useSubmitFellowNewAssesmentMutation,
} from "store/services/providerAPI";
import Swal from "sweetalert2";
import {
  capitalize,
  replaceUnderscoresWithSpaces,
  extractErrorMessage,
} from "utils/strings";
import { ReactComponent as CalendarIcon } from "images/svg/calendar.svg";
import { ReactComponent as ClockIcon } from "images/svg/clock.svg";
import { ReactComponent as MoreIcon } from "images/svg/more.svg";
import FilterModal from "../components/FilterModal";
import * as Yup from "yup";
import { useFormik } from "formik";

const Assesments = () => {
  const [active, setActive] = useState(null);
  const [rejected, setRejected] = useState(null);
  const [skip] = useState(false);
  const [filter, setFilter] = useState("page=1");
  const [modalFilter, setModalFilter] = useState(null);
  const [search, setSearch] = useState("");

  const [searched, setSearched] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const {
    data,
    isLoading: fetchingAssesment,
    isFetching: filtering,
  } = useRetrieveFellowAvailableAssesmnetsQuery(filter, {
    skip: skip,
  });

  const [submitFellowNewAssesment, { isLoading, isSuccess }] =
    useSubmitFellowNewAssesmentMutation();

  const handleSubmit = async (values) => {
    const payload = {
      link: values?.link,
      assessment_id: active?.id,
    };
    await submitFellowNewAssesment(payload)
      .unwrap()
      .then(() => {
        toast.success(`✔️ Assignment submitted Succesfully !`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          title: error?.data?.code
            ? replaceUnderscoresWithSpaces(error?.data?.code)
            : "Error Occured",
          text:
            extractErrorMessage(error?.data?.errors ?? []) ??
            error?.data?.detail,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      link: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const results = data?.data?.results ?? [];
  const attempted_assessment_ids =
    data?.data?.attempted_assessment_ids_with_status ?? [];

  useEffect(() => {
    if (isSuccess) {
      setActive(null);
    }
  }, [isSuccess]);

  const handleInputChange = (event) => {
setSearch(event.target.value);
  };

  useEffect(() => {
    if (modalFilter) {
      const filterString = `page=1${
        modalFilter?.module_id?.length > 0
          ? `&module_id=${modalFilter?.module_id}`
          : ""
      }${
        String(modalFilter?.week_number)?.length > 0
          ? `&week_number=${modalFilter?.week_number}`
          : ""
      }`;
      setFilter(filterString);
      setFiltered(true)
      setShowFilter(false);
    }
  }, [modalFilter]);

  return (
    <Fragment>
      <FilterModal showModal={showFilter} setShowFilter={setShowFilter} setFilter={setModalFilter} />
      {fetchingAssesment ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : data ? (
        <>
          <div className="row">
            <Col xl={12}>
              <section className="custom-tab-1">
                <div className="widget-heading d-flex justify-content-between align-items-center">
                  <h3 className="m-0">Assignments</h3>
                </div>

                <div className={`card-header bg-white ${filtering ? "disabled" : ""}`}>
                  <div className="d-flex  align-items-center">
                    {(filtered || searched) && (
                      <button
                        onClick={() => {
                          setSearch("");
                          setSearched(false);
                          setFiltered(false);
                          setFilter(`page=1`);
                          setModalFilter(null);
                        }}
                        className="btn text-danger"
                      >
                        Reset Filter
                      </button>
                    )}
                  </div>

                  <div className="d-flex  align-items-center">
                    <input
                      className="ml-2 input-search form-control w-100"
                      value={search}
                      onChange={handleInputChange}
                      placeholder="Search by title"
                    />

                    <button
                      type="button"
                      className="btn btn-outline ms-2 "
                      onClick={() => {
                        if (search?.length > 0) {
                          setFilter(`page=1&search=${search}`);
                          setFiltered(false);
                          setSearched(true);
                        }
                      }}
                    >
                      Search
                    </button>

                    <button
                      type="button"
                      className="btn btn-primary ml ms-2"
                      onClick={() => {
                        setShowFilter(true);
                      }}
                    >
                      Filter
                    </button>
                  </div>
                </div>

                {filtering ? (
                  <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
                    <FadeLoader color="#36d7b7" />
                  </div>
                ) : results?.length > 0 ? (
                  results.map((item) => {
                    const attemptedAssessment =
                      attempted_assessment_ids.find(
                        (attempt) => attempt?.assessment_id === item?.id
                      ) ?? null;


                    const disabled =
                      attemptedAssessment?.status === "submitted" ||
                      attemptedAssessment?.status === "approved"
                        ? true
                        : false;

                    return (
                      <div className="card-schedule" key={item?.id}>
                        <span className={`side-label bg-primary`}></span>
                        <div className="up-comming-schedule no-wrap">
                          <div className="assesment-details">
                            <h4>
                              {item?.title}{" "}
                              <span>( Module {item?.module?.name})</span>
                            </h4>
                            <div className="mb-sm-0 mb-2">
                              <span> {item?.course?.title} - </span>

                              <span> Week {item?.week_number}</span>
                            </div>
                          </div>

                          <div className="d-flex flex-column assesment-duration">
                            <div className="d-flex align-items-center">
                              <CalendarIcon />
                              <span>
                                {dayjs(item.start_date).format("MMMM D, YYYY")}{" "}
                                to {dayjs(item.end_date).format("MMMM D, YYYY")}
                              </span>
                            </div>
                            <div className="mb-sm-0 mb-2">
                              <span> Duration: {item?.duration} days</span>
                            </div>
                          </div>

                          <div className="d-flex flex-column  assesment-status">
                            <div className="d-flex align-items-center">
                              <ClockIcon />
                              <span>
                                {capitalize(
                                  attemptedAssessment?.status ?? "Not Submitted"
                                )}
                              </span>
                            </div>
                            <div className="mb-sm-0 mb-2">
                              <span>
                                {" "}
                                Link:{" "}
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-primary text-bold"
                                  href={item?.link}
                                >
                                  {item?.link?.substring(0, 25)}
                                </a>{" "}
                              </span>
                            </div>
                          </div>
                          <Dropdown className="select-dropdown me-2 assesment-cta">
                            <Dropdown.Toggle
                              as="div"
                              className="i-false dashboard-select  selectBtn btn-dark"
                            >
                              <MoreIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className={
                                  attemptedAssessment?.status?.toLowerCase() ===
                                    "submitted" ||
                                  attemptedAssessment?.status?.toLowerCase() ===
                                    "approved"
                                    ? "disabled"
                                    : ""
                                }
                                onClick={() => setActive(item)}
                              >
                                Submit Assignment
                              </Dropdown.Item>
                              <Dropdown.Item
                                rel="noopener noreferrer"
                                href={item?.link}
                                className={disabled ? "disabled" : ""}
                              >
                                View assignment
                              </Dropdown.Item>
                              {attemptedAssessment?.status ===
                                "disapproved" && (
                                <Dropdown.Item
                                  onClick={() => {
                                    setRejected(item);
                                  }}
                                >
                                  View ALC's comment
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <EmptyNotice text="No data yet" />
                )}
              </section>
            </Col>
          </div>
        </>
      ) : null}

      <Modal className="modal fade" show={active ? true : false}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Make Submission</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setActive(null)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <form className="comment-form" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Link</strong>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="link"
                      name="link"
                      placeholder="Submission Link"
                      {...formik.getFieldProps("link")}
                    />

                    {formik.touched.link && formik.errors.link ? (
                      <div className="text-danger mt-2 fs-12">
                        {formik.errors.link}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-12 d-flex justify-content-end">
                  <div className="form-group mb-3">
                    <button
                      type="submit"
                      className="submit btn btn-pigment-green"
                    >
                      {isLoading ? (
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm ms-2"
                        ></span>
                      ) : (
                        `Submit`
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal className="modal fade" show={rejected ? true : false}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">ALC's Comment</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setRejected(null)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <p>{capitalize(rejected?.reason ?? "")}</p>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default Assesments;

const FormSchema = Yup.object().shape({
  link: Yup.string()
    .required("Link is required")
    .url("Invalid URL. Please enter a valid link."),
});
