/** @format */

import React, { Fragment, useState, useEffect } from "react";
import { FadeLoader } from "react-spinners";
import { Col, Modal, Button, Dropdown } from "react-bootstrap";
import dayjs from "dayjs";
import EmptyNotice from "component/ErrorNotice";
import { useGetFellowAssesmentsSubmissionQuery } from "store/services/providerAPI";
import { capitalize } from "utils/strings";
import { ReactComponent as CalendarIcon } from "images/svg/calendar.svg";
import { ReactComponent as ClockIcon } from "images/svg/clock.svg";
import { ReactComponent as MoreIcon } from "images/svg/more.svg";
import FilterModal from "../components/SubmissionModal";

const Submissions = () => {
  const [skip] = useState(false);
  const [rejected, setRejected] = useState(null);
  const [filter, setFilter] = useState("page=1");
  const [filtered, setFiltered] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);

  const {
    data,
    isLoading: fetchingAssesment,
    isFetching: filtering,
  } = useGetFellowAssesmentsSubmissionQuery(filter, {
    skip,
  });

  const results = data?.data?.results ?? [];

  useEffect(() => {
    if (modalFilter) {
      const filterString = `page=1${
        modalFilter?.module_id?.length > 0
          ? `&module_id=${modalFilter?.module_id}`
          : ""
      }${
        modalFilter?.status?.length > 0 ? `&status=${modalFilter?.status}` : ""
      }`;
      setFilter(filterString);
      setFiltered(true);
      setShowFilter(false);
    }
  }, [modalFilter]);

  return (
    <Fragment>
      <FilterModal
        showModal={showFilter}
        setShowFilter={setShowFilter}
        setFilter={setModalFilter}
      />

      {fetchingAssesment ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : data ? (
        <>
          <div className="row">
            <Col xl={12}>
              <section className="custom-tab-1">
                <div className="widget-heading d-flex justify-content-between align-items-center">
                  <h3 className="m-0">Submissions</h3>
                </div>

                <div
                  className={`card-header bg-white ${
                    filtering ? "disabled" : ""
                  }`}
                >
                  <div className="d-flex  align-items-center">
                    {filtered && (
                      <button
                        onClick={() => {
                          setFiltered(false);
                          setFilter(`page=1`);
                          setModalFilter(null);
                        }}
                        className="btn text-danger"
                      >
                        Reset Filter
                      </button>
                    )}
                  </div>

                  <div className="d-flex  align-items-center">
                    <button
                      type="button"
                      className="btn btn-primary ml ms-2"
                      onClick={() => {
                        setShowFilter(true);
                      }}
                    >
                      Filter
                    </button>
                  </div>
                </div>

                {filtering ? (
                  <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
                    <FadeLoader color="#36d7b7" />
                  </div>
                ) : results?.length > 0 ? (
                  results.map((item, ind) => (
                    <div className="card-schedule" key={ind}>
                      <span className={`side-label bg-primary`}></span>
                      <div className="up-comming-schedule no-wrap">
                        <div className="assesment-details">
                          <h4> {item?.assessment?.title}</h4>
                          <div className="mb-sm-0 mb-2">
                            <span> Week {item?.assessment?.week_number}</span>
                          </div>
                        </div>

                        <div className="d-flex flex-column assesment-duration">
                          <div className="d-flex align-items-center">
                            <CalendarIcon />
                            <span>
                              {dayjs(item.assessment?.start_date).format(
                                "MMMM D, YYYY"
                              )}{" "}
                              to{" "}
                              {dayjs(item.assessment?.end_date).format(
                                "MMMM D, YYYY"
                              )}
                            </span>
                          </div>
                          <div className="mb-sm-0 mb-2">
                            <span>
                              {" "}
                              Duration: {item?.assessment?.duration} days
                            </span>
                          </div>
                        </div>

                        <div className="d-flex flex-column assesment-status">
                          <div className="d-flex align-items-center">
                            <ClockIcon />
                            <span>{capitalize(item?.status)}</span>
                          </div>
                          <div className="mb-sm-0 mb-2">
                            <span>
                              {" "}
                              Link:{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-primary text-bold"
                                href={item?.assessment?.link}
                              >
                                {item?.assessment?.link?.substring(0, 25)}
                              </a>{" "}
                            </span>
                          </div>
                        </div>
                        <Dropdown className="select-dropdown me-2  assesment-cta">
                          <Dropdown.Toggle
                            as="div"
                            className="i-false dashboard-select  selectBtn btn-dark"
                          >
                            <MoreIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setRejected(item);
                              }}
                            >
                              View ALCs comment
                            </Dropdown.Item>
                            <Dropdown.Item
                              rel="noopener noreferrer"
                              href={item?.assessment?.link}
                            >
                              View assignment
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  ))
                ) : (
                  <EmptyNotice text="No data yet" />
                )}
              </section>
            </Col>
          </div>
        </>
      ) : null}

      <Modal className="modal fade" show={rejected ? true : false}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">ALC's Comment</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setRejected(null)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <p>{capitalize(rejected?.reason)}</p>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default Submissions;
