/**
 * eslint-disable no-unused-vars
 *
 * @format
 */

/** @format */

import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { Button } from "react-bootstrap";
import { extractFilterValues } from "utils/functions";
import { ATTENDANCE_COLUMNS } from "./helpers/Columns/Columns";
import "./helpers/filtering.css";
import FilterModal from "./helpers/FilterModal";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
// import { useGetCohortsQuery } from "store/services/adminAPI";

import Swal from "sweetalert2";
import { useBulkMarkAttendanceMutation } from "store/services/providerAPI";
import { extractErrorMessage, replaceUnderscoresWithSpaces } from "utils/strings";

export const AttendaceFellowsTable = ({
  fellows,
  metadata,
  setFilter,
  filter,
  setSkip,
  filtering,

  cohort,
  schedule,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [filtered, setFiltered] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [bulkMarkAttendance, { isLoading }] = useBulkMarkAttendanceMutation();

  const handleRowSelection = useCallback(
    (id) => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    },
    [selectedIds]
  );

  const handleSelectAll = () => {
    if (!selectAll) {
      const allIds = fellows.map((_F) => _F?.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (modalFilter) {
      // setSearch("");
      const values = extractFilterValues(modalFilter);

      const filterString = `page=1${
        values?.status?.length > 0 ? `&status=${values?.status?.join(",")}` : ""
      }${values?.gender?.length > 0 ? `&gender=${values?.gender}` : ""}${
        values?.selectedStates?.length > 0
          ? `&state_of_origin=${values?.selectedStates?.join(",")}`
          : ""
      }${
        values?.selectedLGAs?.length > 0
          ? `&lga=${values?.selectedLGAs?.join(",")}`
          : ""
      }${
        values?.selectedCourse?.length > 0
          ? `&course=${values?.selectedCourse?.join(",")}`
          : ""
      }${
        values?.cohort_id?.length > 0 ? `&cohort_id=${values?.cohort_id}` : ""
      }`;

      setFiltered(true);
      setFilter(filterString);
      setShowFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  useEffect(() => {
    setData([...fellows]);
    setSkip(true);
  }, [fellows, setSkip]);

  useEffect(() => {
    if (filter) {
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const columns = useMemo(
    () => ATTENDANCE_COLUMNS(selectedIds, handleRowSelection, cohort, schedule),
    [selectedIds, handleRowSelection, cohort, schedule]
  );
  // const data = useMemo(() => fellows, [fellows]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const restoreFellows = () => {
    setFilter(`page=1&attendance_schedule=${schedule?.id}`);
    setModalFilter(null);
    setClear(true);
  };

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  const handleSubmit = async () => {
    await bulkMarkAttendance({
      schedule_id: schedule?.id,
      status: "present",
      fellows: [...selectedIds],
      confirmation_page_link:
        "https://app.3mtt.training/fellow/confirm-attendance",
    })
      .unwrap()
      .then((data) => {
        Swal.fire({
          title: "Success",
          text: `Week ${
            schedule?.week_number
          } Attendance has now been completed for ${
            selectedIds?.length
          } Fellow${selectedIds?.length > 1 ? "s" : ""}`,
          // iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          icon: "success",
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Ok",
        });
        setSelectedIds([]);
        // window.location.reload();
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error)
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: formattedError,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <>
      <FilterModal
        setFilter={setModalFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        restoreFellows={restoreFellows}
        clearFilter={clear}
        cohorts={[]}
      />

      <div className="card">
        <div className="card-header">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div className="d-flex  justify-content-between  align-items-center">
              <div className="mr-2">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={() => handleSelectAll()}
                />
              </div>
              <h4 className="card-title text-pigment-green">All Fellows</h4>
              {selectedIds?.length > 0 && (
                <div className="ml-2">
                  <Button
                    className="ms-2 me-2 py-3 btn-pigment-green-outline "
                    style={{ width: "100% !important" }}
                    onClick={() => {
                      Swal.fire({
                        title: "Confirm Action",
                        text: "Are you sure you want to proceed with this action?",
                        iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                        showCloseButton: true,
                        customClass: {
                          icon: "no-border",
                        },

                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: "Proceed",
                        cancelButtonText: "Cancel",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleSubmit();
                        }
                      });
                    }}
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      ` Mark Attendance`
                    )}
                  </Button>
                </div>
              )}

              {searched && (
                <button
                  onClick={() => {
                    setSearch("");
                    setSearched(false);
                    setFiltered(false);
                    setClear(!clear);
                    setFilter(`page=1&attendance_schedule=${schedule?.id}`);
                  }}
                  className="btn text-danger"
                >
                  Reset Filter
                </button>
              )}
            </div>

            <div className="d-flex  align-items-center">
              <input
                className="ml-2 input-search form-control w-100"
                value={search}
                onChange={handleInputChange}
                placeholder="Search by name or email or fellow ID"
              />

              <button
                type="button"
                className="btn btn-outline ms-2 "
                onClick={() => {
                  if (search?.length > 0) {
                    setClear(!clear);
                    setFilter(
                      `page=1&attendance_schedule=${schedule?.id}&search=${search}`
                    );
                    setFiltered(false);
                    setSearched(true);
                  }
                }}
              >
                Search
              </button>
              {/* <Button
                className="ms-2 me-2 py-3 btn-pigment-green-outline "
                style={{ width: "100% !important" }}
                onClick={() => setShowFilter(true)}
              >
                Filter
              </Button> */}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="dataTables_wrapper">
              {filtering ? (
                <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
                  <FadeLoader color="#36d7b7" />
                </div>
              ) : (
                <>
                  {data.length > 0 ? (
                    <>
                      <table
                        {...getTableProps()}
                        className="table dataTable display"
                      >
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                >
                                  {column.render("Header")}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {rows.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                  return (
                                    <td {...cell.getCellProps()}>
                                      {" "}
                                      {cell.render("Cell")}{" "}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                        <button
                          className={`btn btn-outline ${
                            hasPrev ? "" : "disabled"
                          } `}
                          onClick={() => {
                            const _newPage = Number(metadata?.page - 1) || 1;
                            setFilter(
                              `page=${_newPage}&attendance_schedule=${schedule?.id}`
                            );
                          }}
                        >
                          Previous
                        </button>

                        {metadata?.count && (
                          <div className="d-flex">
                            <p className="mb-0">
                              Page <strong>{metadata?.page}</strong> of{" "}
                              <strong>
                                {Math.ceil(metadata?.count / 100)}
                              </strong>{" "}
                              {/* <strong>{Math.ceil(metadata?.count / 1)}</strong>{" "} */}
                              &nbsp;
                            </p>
                            <p className="mb-0">
                              Total <strong>{metadata?.count}</strong>
                            </p>
                          </div>
                        )}

                        <button
                          className={`btn btn-outline ${
                            hasNext ? "" : "disabled"
                          } `}
                          onClick={() => {
                            const _newPage = Number(metadata?.page || 0) + 1;
                            setFilter(
                              `page=${_newPage}&attendance_schedule=${schedule?.id}`
                            );
                            // setFilter(`page=${_newPage}&page_size=1`);
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="card">
                      <div className="card-body text-center ai-icon  text-primary">
                        <BagIcon />
                        <h4 className="my-2">
                          No fellows for the specified filter
                        </h4>
                        {/* <button
                          onClick={() => setShowFilter(true)}
                          className="btn my-2 btn-primary btn-lg px-4"
                        >
                          Filter
                        </button> */}
                        <button
                          onClick={() => restoreFellows()}
                          className="btn my-2  btn-pigment-green-outline btn-lg px-4 mx-2"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AttendaceFellowsTable;
